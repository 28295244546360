.menuWrapper {
    display: inline-block;
    position: relative;
}

.menuButton {
    background: none;
    border: none;
    cursor: pointer;
}

.menuList {
    position: absolute;
    top: 100%;
    right: 0;
    margin: 0;
    padding: 5px 0;
    list-style-type: none;
    background-color: var(--white);
    min-width: 100px;
    z-index: 1;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.menuItem {
    padding: 5px 10px 5px 16px;
    align-items: center;
    align-self: stretch;
    cursor: pointer;
    color: var(--dark-charcoal);
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.menuItemRed {
    color: var(--vivid-red);
}
