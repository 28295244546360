@import 'colors.css';

.label {
  padding: 2px 12px 1px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.label.disconnected {
  background-color: var(--soft-silver);
}

.label.connected {
  background-color: var(--badge-success-background);
}

.label.unhealthy {
  background-color: var(--sunshine-yellow);
  line-height: 18px;
  color: #92400e;
  font-weight: 500;
}

.icon {
  margin-right: 8px;
}

.icon.connected {
  width: 14px;
}
