@import "colors.css";

.card {
    display: flex;
    flex-direction: column;
    background-color: var(--frost-white);
    border-radius: 8px;
    padding: 16px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    position: relative;
}

.cardContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
}

.signeturContainer {
    padding: 16px 87px 8px;
    border-top: 1px solid #E5E5E5;
}

.detailsContainer {
    display: flex;
    flex: 1;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    flex-wrap: wrap;
    
}

.detailItem {
    display: flex;
    flex-direction: column;
}

.datesContainer {
    display: flex;
    gap: 24px;
}

.detailLabel {
    color: var(--dark-charcoal);
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    min-width: 100px;
}

.detailValue {
    color: var(--dark-charcoal);
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    opacity: 0.5;
    min-width: 100px;
}
