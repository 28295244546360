@import 'colors.css';

.wrapper {
  border-radius: 8px;
  border: 1px solid var(--light-grayish-blue);
  background: var(--white);
  display: flex;
  padding: 20px 30px 24px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
  color: var(--dark-charcoal);
}

.header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 16px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.lastUpdated {
  color: var(--spanish-gray);
  font-size: 13px;
  margin-right: 12px;
}

.lastUpdated > span:nth-child(1) {
  opacity: 0.7;
}

.infoContainer {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.body {
  font-size: 14px;
}

.body > p {
  margin-top: 16px;
}

.fields {
  margin-top: 39.5px;
  font-weight: 500;
}

.fields > :not(:last-child) {
  margin-bottom: 23px;
}

.deviceType {
  font-size: 22px;
  text-transform: capitalize;
}
