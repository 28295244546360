@import "colors.css";

.loader {
    max-width: 270px;
    width: 100%;
}

.loaderLabel {
    height: 18px;
    width: 50%;
    margin-bottom: 10px;
    background: var(--light-grayish-blue);
}

.loaderInput {
    height: 48px;
    background: var(--light-grayish-blue);
}

.wrapper {
    width: 100%;
}

.label {
    display: block;
    margin-bottom: 10px;
}

.required {
    display: inline-block;
    color: var(--pastel-red);
    font-size: 13px;
    line-height: 18px;
    margin-left: 1px;
}

@media only screen and (max-width: 480px) {
    .wrapper {
        max-width: initial;
    }

    .loader {
        max-width: initial;
    }
}

.input {
    display: block;
    width: 100%;
    font-size: 13px;
    line-height: 30px;
    outline: none;
    padding: 10px 16px;
    border: solid 1px var(--light-grayish-blue);
    border-radius: 2px;
}

.input::placeholder {
    font-size: 12px;
    font-weight: 300;
}

.input:enabled:hover {
    border: solid 1px var(--color-gray-dark);
}

.input:focus {
    box-shadow: 0 0 0 1px var(--color-shadow);
}

.inputInvalid {
    border-color: var(--pastel-red);
}

.inputInvalid::placeholder {
    color: var(--pastel-red);
}

.inputInvalid:hover {
    border: solid 1px var(--pastel-red);
}

.inputInvalid:focus {
    box-shadow: 0 0 0 1px var(--pastel-red);
}

input:disabled {
    border-color: var(--gainsboro);
    opacity: 0.3;
    background-color: var(--chinese-silver);
}

input:disabled:hover {
    border-color: var(--gainsboro);
}
