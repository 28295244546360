@import "colors.css";

.tabSwitcher {
  display: flex;
  max-width: max-content;
  align-items: center;
  background: none;
  padding: 0;
}

.tabSwitcher button {
  color: var(--deep-midnight-blue);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  flex: 1;
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  text-align: center;
  transition: color 0.3s;
}

.tabSwitcher button.activeTab {
  font-weight: 600;
  border-bottom: 2px solid var(--deep-midnight-blue);
}

.tabSwitcher button:not(.activeTab):hover {
  opacity: 0.8;
}

