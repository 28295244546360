@import "colors.css";

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
}

.title {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 10px;
    width: fit-content;
}

.addButton {
    width: 120px;
}
