@import "colors.css";

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
}

.title {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
}

.spaceAbove {
    margin-top: 28px;
}

.supplementRow {
    display: flex;
    flex-direction: column;
}

.closeIcon {
    align-self: flex-end;
}

.formRow {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    align-items: flex-start;
}

.inputWrapper {
    flex: 1;
    min-width: 150px;
}

.inputWrapper:first-child {
    flex: 2.5;
    min-width: 300px;
}

.dateInputWrapper {
    flex: 6px;
    max-width: 160px;
    position: relative;
}

.sigInput {
    display: flex;
    width: 100%;
    margin-top: 8px;
}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 10px;
    width: fit-content;
}

.addButton {
    width: 120px;
}

.dateInput {
    position: relative;
}

.dateInput input {
    padding-right: 25px;
}
