@import 'colors.css';

.sectionContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.sectionTitle {
    font-size: 18px;
    font-weight: 600;
    margin: 16px 0;
    color: var(--charcoal-gray);
}

.listContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: auto;
    padding-right: 8px;
}

.emptyState {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    background-color: var(--soft-white);
    border-radius: 8px;
    color: var(--slate-gray);
    font-size: 14px;
}