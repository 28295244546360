.buttonContainer {
    display: flex;
}

.button {
    display: block;
    min-width: 181px;
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    gap: 16px;
}

@media (max-width: 768px) {
    .header {
        flex-direction: column;
    }
}
