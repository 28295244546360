.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  padding: 12px 20px;
  font-size: 13px;
  line-height: 14px;
  cursor: pointer;
  gap: 10px;
  font-family: "Poppins", sans-serif;
  min-width: 120px;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

button:disabled:hover {
  opacity: 0.5 !important;
}

.button.full-width {
  width: 100%;
}
.button.small {
  padding: 8px 14px;
  font-weight: 400;
}
/* Primary-Purple Variant */
.button.primary-purple {
  background-color: var(--deep-midnight-blue);
  color: var(--white);
  border: none;
  font-weight: 400;
}

.button.primary-purple:not(:disabled):hover {
  opacity: 0.8;
}

/* Secondary-Red Variant */
.button.secondary-red {
  background-color: var(--white);
  color: var(--vivid-red);
  border: 1px solid var(--light-red);
  font-weight: 500;
}

.button.secondary-red:not(:disabled):hover {
  background-color: var(--light-red);
}
.button.secondary-red:hover {
  background-color: var(--light-red);
}

/* Primary-Green Variant */
.button.primary-green {
  background-color: var(--forest-green);
  color: var(--white);
  border: 1px solid var(--forest-green);
}

.button.primary-green:disabled {
  background-color: var(--deep-quamarine-green);
  border: 1px solid var(--deep-quamarine-green);
}

.button.primary-green:hover {
  opacity: 0.8;
}

.button.primary-green:not(:disabled):hover {
    opacity: 0.8;
}

/* Secondary-Green Variant */
.button.secondary-green {
  background-color: transparent;
  color: var(--forest-green);
  border: 1px solid var(--forest-green);
}

.button.secondary-green:hover {
  background-color: var(--mint-frost);
}

/* Secondary-Purple Variant */
.button.secondary-purple {
  background-color: transparent;
  color: var(--dark-charcoal);
  border: 1px solid var(--dark-charcoal);
}

.button.secondary-purple:hover {
  background-color: var(--grape-transparent);
}

.button-loading {
  cursor: not-allowed;
  opacity: 0.7;
}

.spinner {
  border: 2px solid var(--light-gray);
  border-top: 2px solid var(--dark-charcoal);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 0.8s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
