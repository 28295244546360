@import 'colors.css';

.loadingContainer {
  margin: 0 auto;
  transform: scale(0.4);
}

.title {
  color: var(--dark-charcoal);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
